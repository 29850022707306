import { Checkbox, Flex, useDisclosure } from '@chakra-ui/react';
import { CollaborationConfigModal } from 'components/modal/CollaborationConfigModal';
import {
	Filter,
	KeyFilter,
	useContextColaboracaoConsenso,
} from '../../context';
import { TooltipHelper } from 'components/tooltip/TooltipHelper';
interface CheckboxGrouping {
	'data-test': string;
	name: string;
	tooltip: string;
	disabledMessage?: string;
}
const configBoxGrouping: Partial<Record<KeyFilter, CheckboxGrouping>> = {
	canal_venda: {
		'data-test': 'checkbox-agrupar_todos_os_canais-page-colaboracao',
		name: 'Agrupar todos os canais',
		tooltip:
			'O agrupamento de todos os canais de venda permite realizar a colaboração por família de produto sem entrar no nível de canal.',
	},
	zona_venda: {
		'data-test': 'checkbox-agrupar_todas_as_zonas-page-colaboracao',
		name: 'Agrupar todas as zonas',
		tooltip:
			'O agrupamento de todas as zonas de venda permite realizar a colaboração por família de produto e canal de venda sem entrar no nível de zona.',
		disabledMessage:
			'A hierarquia comercial deste canal não possui zona de venda relacionada!',
	},
};
export const shouldDisableCheckBox = (filter, selectedFilter, config) => {
	const has_zona_venda = selectedFilter?.canal_venda?.has_zona_venda;
	const disabled =
		filter.disableGrouping ||
		(filter.key === 'zona_venda' && !has_zona_venda);
	return disabled;
};

interface CheckBoxGroupingProps {
	filter: Filter;
}
const CheckBoxGrouping = ({ filter }: CheckBoxGroupingProps): JSX.Element => {
	const config = configBoxGrouping[filter.key];
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { valuesFilter } = useContextColaboracaoConsenso();

	if (config) {
		const disabled = shouldDisableCheckBox(filter, valuesFilter, config);
		return (
			<Flex
				alignItems='center'
				justifyContent='space-between'
				mt={2}
			>
				<TooltipHelper
					message={
						(disabled
							? config.disabledMessage
							: config.tooltip) as string
					}
					disabled={disabled}
				>
					<Checkbox
						data-test={config['data-test']}
						size='sm'
						fontWeight={500}
						onChange={() => onOpen()}
						isChecked={filter?.grouping}
						isDisabled={disabled}
						_disabled={{
							color: 'gray.400',
							cursor: 'not-allowed',
						}}
					>
						{config.name}
					</Checkbox>
				</TooltipHelper>
				<CollaborationConfigModal
					isOpen={isOpen}
					onClose={onClose}
					typeOfModal={filter?.key}
					isCheckboxChecked={!!filter?.grouping}
				/>
			</Flex>
		);
	}
	return <></>;
};
export default CheckBoxGrouping;
