import { Box } from '@chakra-ui/react';
import { Filter } from 'pages/admin/demanda/templateCadastrarColabCons/context';
import { useMemo } from 'react';
import InputClients from './InputClients';
import InputMainFilterBase from './InputMainFilterBase';

type InputsFilterProps = {
	filters: Record<string, Filter>;
	setFilters: any;
	page?: string;
};

const InputsMainFilter = ({
	filters,
	setFilters,
	page,
}: InputsFilterProps): JSX.Element => {
	const renderFilters = useMemo(() => {
		return Object.entries(filters).map(([name, filter]) => {
			if (!filter.show) {
				return <></>;
			}
			return (
				<InputMainFilterBase
					filter={filter}
					setFilters={setFilters}
					name={name}
					key={name}
					dataTest={
						filter?.show
							? `autocomplete-filtro_${filter?.key}-main-page-${page}`
							: ''
					}
				/>
			);
		});
	}, [filters, setFilters]);
	return (
		<Box
			display='flex'
			flexWrap='wrap'
			mx={0}
			gap={0}
		>
			{renderFilters}
			<InputClients page={page} />
		</Box>
	);
};

export default InputsMainFilter;
