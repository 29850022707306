import { Tooltip } from '@chakra-ui/react';
import { IconHelp } from '@tabler/icons';
import { globalStyles } from 'theme/styles';
interface TooltipHelperProps {
	message: string;
	disabled?: boolean;
	children?: React.ReactNode;
}

export const TooltipHelper = ({
	message,
	disabled = false,
	children,
}: TooltipHelperProps): JSX.Element => (
	<>
		<>{children}</>
		<Tooltip
			label={message}
			placement='top'
			bg='gray.200'
			color='blue.800'
			hasArrow
			arrowSize={15}
			shouldWrapChildren
		>
			<IconHelp
				size={16}
				color={
					disabled
						? globalStyles.colors.easyRED[400]
						: globalStyles.colors.secondaryGray[600]
				}
				cursor='pointer'
			/>
		</Tooltip>
	</>
);
