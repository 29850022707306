import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { environmentVars } from 'services/api/variables/environment';
import * as Sentry from '@sentry/react';
if (
	process.env.BACKEND_NESTJS_NODE_ENV === 'staging' ||
	process.env.BACKEND_NESTJS_NODE_ENV === 'production'
) {
	Sentry.init({
		dsn: environmentVars.sentry.dsn,
		environment: environmentVars.sentry.env,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			'localhost',
			/^https:\/\/yourserver\.io\/api/,
		],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

Amplify.configure(environmentVars.aws);

//DOM - Document Object Model - estrutura da pagina
ReactDOM.render(
	<React.StrictMode>
		{/* pega todo conteudo do Dashboard/index.tsx */}
		<Authenticator.Provider>
			<App />
		</Authenticator.Provider>
	</React.StrictMode>,
	document.getElementById('root'),
);
