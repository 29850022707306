import { useEffect, useMemo } from 'react';
import {
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
} from 'react-table';

import {
	Avatar,
	Badge,
	Button,
	Flex,
	Icon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
} from '@chakra-ui/react';

import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import {
	MdChevronLeft,
	MdChevronRight,
	MdFirstPage,
	MdLastPage,
} from 'react-icons/md';

function ManagementTable(props) {
	const { columnsData, tableData, linkEditForm, dataDelete } = props;

	const columns = useMemo(() => columnsData, [columnsData]);
	const data = useMemo(() => tableData, [tableData]);

	const tableInstance = useTable(
		{
			columns,
			data,
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		setGlobalFilter,
		pageOptions,
		gotoPage,
		pageCount,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = tableInstance;

	useEffect(() => {
		setPageSize(100);
	}, []);

	const textColor = useColorModeValue('easyBLUE.300', 'white');
	const borderColor = useColorModeValue(
		'secondaryGray.400',
		'whiteAlpha.100',
	);

	return (
		<>
			<Flex
				direction='column'
				w='100%'
				overflowX={{ sm: 'scroll', lg: 'hidden' }}
				mt={-10}
			>
				<Flex
					w={'fit-content'}
					px='20px'
					mb='20px'
				>
					<SearchBar
						onChange={(e) => setGlobalFilter(e.target.value)}
						h='44px'
						placeholder='Buscar...'
						w={{ lg: '390px' }}
						borderRadius='100px'
					/>
				</Flex>
				<Table
					{...getTableProps()}
					variant='simple'
					color='gray.500'
				>
					<Thead>
						{headerGroups.map((headerGroup, index) => (
							<Tr
								{...headerGroup.getHeaderGroupProps()}
								key={index}
							>
								{headerGroup.headers.map((column, index) => (
									<Th
										{...column.getHeaderProps(
											column.getSortByToggleProps(),
										)}
										pe='10px'
										key={index}
										borderColor={borderColor}
									>
										<Flex
											justify='space-between'
											align='center'
											fontSize={{
												sm: '10px',
												lg: '11px',
											}}
											color='gray.400'
										>
											{column.render('Header')}
										</Flex>
									</Th>
								))}
							</Tr>
						))}
					</Thead>

					<Tbody {...getTableBodyProps()}>
						{page.map((row, index) => {
							prepareRow(row);
							return (
								<Tr
									{...row.getRowProps()}
									key={index}
								>
									{row.cells.map((cell, index) => {
										let data: string | JSX.Element = '';
										if (cell.column.Header === 'Usuário') {
											data = (
												<Flex align='center'>
													<Avatar
														name={cell.value}
														w='48px'
														h='48px'
														me={3}
													/>
													<Flex direction='column'>
														<Text
															color={textColor}
															fontSize='md'
															fontWeight='700'
														>
															{cell.value}
														</Text>
													</Flex>
												</Flex>
											);
										} else if (
											cell.column.Header === 'Login'
										) {
											data = (
												<Text
													color={textColor}
													fontSize='md'
													fontWeight='500'
												>
													{cell.value}
												</Text>
											);
										} else if (
											cell.column.Header === 'Tipo'
										) {
											data = (
												<Flex
													flexWrap={
														cell.value.length > 3
															? 'wrap'
															: 'nowrap'
													}
													minW={60}
												>
													{cell.value.map(
														(item, index) => (
															<Text
																color={
																	textColor
																}
																fontSize='md'
																fontWeight='500'
																key={index}
																me={2}
															>
																{index ===
																cell.value
																	.length -
																	1
																	? item
																	: `${item},`}
															</Text>
														),
													)}
												</Flex>
											);
										} else if (
											cell.column.Header === 'Status'
										) {
											data = (
												<Badge
													colorScheme={
														cell.value === 'inativo'
															? 'red'
															: 'green'
													}
													color={
														cell.value === 'inativo'
															? 'red.500'
															: 'green.500'
													}
													fontSize='sm'
													fontWeight='600'
													w={'120px'}
													justifyContent={'center'}
													alignItems={'center'}
													textAlign={'center'}
													display={'flex'}
												>
													{cell.value.toLowerCase()}
												</Badge>
											);
										} else if (cell.column.Header === ' ') {
											data = (
												<cell.column.jsx
													row={row.original}
													dataDelete={dataDelete}
													idDelete={
														cell.column.module
															? tableData[row.id]
																	.id[0]
															: {
																	email: tableData[
																		row.id
																	].login,
																	deleteDb:
																		true,
															  }
													}
													linkBtnEdit={
														cell.column.module
															? `${linkEditForm}/${
																	tableData[
																		row.id
																	].id[0]
															  }`
															: `${linkEditForm}/${
																	tableData[
																		row.id
																	].id
															  }`
													}
													passwordReset={
														tableData[row.id]
													}
												/>
											);
										}
										return (
											<Td
												{...cell.getCellProps()}
												key={index}
												fontSize={{ sm: '14px' }}
												minW={{
													sm: '120px',
													md: '200px',
													lg: 'auto',
												}}
												borderColor='transparent'
												mt='20px !important'
												py='10px !important'
											>
												{data}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
				<Flex
					justifyContent='space-between'
					m={4}
					alignItems='center'
				>
					<Flex>
						<Button
							variant='no-effects'
							onClick={() => gotoPage(0)}
							isDisabled={!canPreviousPage}
							transition='all .5s ease'
							w='50px'
							h='40px'
							borderRadius='8px'
							bg='transparent'
							border='1px solid'
							borderColor={useColorModeValue('gray.200', 'white')}
							_hover={{
								bg: 'whiteAlpha.100',
								opacity: '0.7',
							}}
						>
							<Icon
								as={MdFirstPage}
								w='16px'
								h='16px'
								color={textColor}
							/>
						</Button>
						<Button
							variant='no-effects'
							onClick={previousPage}
							isDisabled={!canPreviousPage}
							transition='all .5s ease'
							w='50px'
							h='40px'
							borderRadius='8px'
							bg='transparent'
							border='1px solid'
							borderColor={useColorModeValue('gray.200', 'white')}
							_hover={{
								bg: 'whiteAlpha.100',
								opacity: '0.7',
							}}
						>
							<Icon
								as={MdChevronLeft}
								w='16px'
								h='16px'
								color={textColor}
							/>
						</Button>
					</Flex>
					<Flex alignItems='center'>
						<Text
							me='10px'
							minW='max-content'
							fontSize='sm'
							color='gray.500'
							fontWeight='normal'
						>
							Linhas por página
						</Text>
						<Select
							w={20}
							size='md'
							borderRadius='8px'
							variant='main'
							mr={8}
							value={pageSize}
							onChange={(e) =>
								setPageSize(Number(e.target.value))
							}
						>
							{[6, 7, 8, 9, 10, 100].map((pageSize) => (
								<option
									key={pageSize}
									value={pageSize}
								>
									{pageSize}
								</option>
							))}
						</Select>
						<Text
							flexShrink={0}
							mr={8}
							minW='max-content'
							fontSize='sm'
							color='gray.500'
							fontWeight='normal'
						>
							Página{' '}
							<Text
								fontWeight='bold'
								as='span'
							>
								{pageIndex + 1}
							</Text>{' '}
							de{' '}
							<Text
								fontWeight='bold'
								as='span'
							>
								{pageOptions.length}
							</Text>
						</Text>
						<Text
							flexShrink={0}
							me='10px'
							minW='max-content'
							fontSize='sm'
							color='gray.500'
							fontWeight='normal'
						>
							Ir para a página
						</Text>{' '}
						<NumberInput
							maxW={28}
							size='md'
							min={1}
							max={pageOptions.length}
							onChange={(value) => {
								const page = value ? +value - 1 : 0;
								gotoPage(page);
							}}
							defaultValue={pageIndex + 1}
						>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
					</Flex>
					<Flex>
						<Button
							variant='no-effects'
							onClick={nextPage}
							isDisabled={!canNextPage}
							transition='all .5s ease'
							w='50px'
							h='40px'
							borderRadius='8px'
							bg='transparent'
							border='1px solid'
							borderColor={useColorModeValue('gray.200', 'white')}
							_hover={{
								bg: 'whiteAlpha.100',
								opacity: '0.7',
							}}
						>
							<Icon
								as={MdChevronRight}
								w='16px'
								h='16px'
								color={textColor}
							/>
						</Button>
						<Button
							variant='no-effects'
							onClick={() => gotoPage(pageCount - 1)}
							isDisabled={!canNextPage}
							transition='all .5s ease'
							w='50px'
							h='40px'
							borderRadius='8px'
							bg='transparent'
							border='1px solid'
							borderColor={useColorModeValue('gray.200', 'white')}
							_hover={{
								bg: 'whiteAlpha.100',
								opacity: '0.7',
							}}
						>
							<Icon
								as={MdLastPage}
								w='16px'
								h='16px'
								color={textColor}
							/>
						</Button>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
}

export default ManagementTable;
