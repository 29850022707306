import {
	Box,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { useState } from 'react';
import { ColumnsTableFileUpload } from './ColumnsTableFileUpload';

interface FileUploadErrorsModalProps {
	isOpen: boolean;
	onClose: () => void;
	headerText: string;
	uploadData: any;
	nameFile: string;
	columnsData: any[];
}
export const FileUploadErrorsModal = (props: FileUploadErrorsModalProps) => {
	const { isOpen, onClose, headerText, uploadData, nameFile, columnsData } =
		props;
	const [totalValue] = useState<number>(+uploadData?.quantidade_erros);

	const easyRED = useColorModeValue('easyRED.300', 'easyRED.200');
	const easyBLUE = useColorModeValue('easyBLUE.300', 'easyBLUE.200');
	const bg = useColorModeValue('secondaryGray.300', 'secondaryGray.400');
	const borderColor = useColorModeValue(
		'secondaryGray.400',
		'whiteAlpha.100',
	);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={'6xl'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={'30px'}
				h={'max-content'}
				maxW={'1570px'}
			>
				<ModalHeader
					textColor={easyBLUE}
					fontSize={'24px'}
					fontWeight='700'
				>
					{headerText}
					<Text
						color='secondaryGray.500'
						fontSize='16px'
						fontWeight='400'
					>
						Confirme se as informações do arquivo estão corretas
						antes de continuar.
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box display='flex'>
						<AlertComponent
							description={nameFile ? nameFile : ''}
							noIcon
						/>
						{totalValue === 0 ? (
							<AlertComponent
								status={'success'}
								title={'Parabéns!'}
								description={
									'Seu arquivo está pronto para a importação!'
								}
							/>
						) : (
							<AlertComponent
								status={'error'}
								title={'Atenção'}
								description={
									'Encontramos os erros apontados abaixo. Por favor corrija localmente na sua máquina esses erros e faça o upload novamente do arquivo.'
								}
							/>
						)}
						{totalValue !== 0 && (
							<AlertComponent
								description={`Total de erros no arquivo: ${totalValue}`}
								noIcon
							/>
						)}
					</Box>
					<Box
						h={'500px'}
						backgroundColor={bg}
						border='1px solid'
						borderColor={borderColor}
						borderRadius='16px'
						w='100%'
						minH='100%'
						display='flex'
						flexDir={'column'}
						alignItems='center'
						justifyContent='center'
						data-test={'modal-file-upload-errors'}
					>
						{
							// Provisório para exibição da tabela com erro e sem erro
							totalValue > 0 && (
								<ColumnsTableFileUpload
									columnsData={columnsData}
									data={uploadData.linha}
									totalValue={totalValue}
								/>
							)
						}
					</Box>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={onClose}
						variant={'outline'}
						mr={'24px'}
						borderRadius={'100px'}
						borderColor={'easyBLUE.150'}
						textColor={easyBLUE}
					>
						Cancelar
					</Button>
					<Button
						variant={'outline'}
						bgColor={easyRED}
						textColor={'white'}
						opacity={'0.4'}
						borderRadius={'100px'}
					>
						Importar
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
