import React from 'react';
import {
	Box,
	Text,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	ResponsiveValue,
} from '@chakra-ui/react';

interface ModalInconsistencyUpdateTableProps {
	changedEntities?: any;
	pathCompanyName?: string;
	entityName?: string;
}

const styles = {
	box: {
		mt: 4,
		overflowX: 'auto' as ResponsiveValue<'auto'>,
	},
	text: {
		fontWeight: 700,
		color: 'easyBLUE.300',
		fontSize: 'lg',
		mb: 2,
	},
	tableContainer: {
		maxHeight: '400px',
		overflowY: 'auto' as ResponsiveValue<'auto'>,
	},
};
const COLUMNIGNORE = ['id'];
const ModalInconsistencyUpdateTable: React.FC<
	ModalInconsistencyUpdateTableProps
> = ({ changedEntities, pathCompanyName, entityName }) => {
	if (!changedEntities || changedEntities?.length === 0) {
		return null;
	}
	return (
		<Box {...styles.box}>
			<Text {...styles.text}> {entityName} inconsistentes:</Text>
			<Box
				as='hr'
				my={4}
			/>
			<Box {...styles.tableContainer}>
				<Table variant='simple'>
					<Thead>
						<Tr>
							{Object.keys(changedEntities[0]).map(
								(key, index) =>
									!COLUMNIGNORE.includes(key) && (
										<Th key={index}>{key}</Th>
									),
							)}
							<Th key={'edit'}>{'Editar Produto'}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{changedEntities.map((product, index) => (
							<Tr key={index}>
								{Object.entries(product).map(
									([key, value], i) =>
										!COLUMNIGNORE.includes(key) ? (
											<Td key={i}>{value}</Td>
										) : null,
								)}
								<Td key={'edit'}>
									<a
										href={`/${pathCompanyName}/cadastros/produto/editar/${product.id}`}
									>
										Editar
									</a>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</Box>
	);
};

export default ModalInconsistencyUpdateTable;
