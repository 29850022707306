import {
	defaultInputStyle,
	defaultFormStyle,
	columnFlexProps,
	formFlexProps,
	rowFlexProps,
} from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validateRequired } from '../../utils/forms/validations';
import { useDisclosure } from '@chakra-ui/react';
import { CreateMemberModal } from 'components/modal/CreateMemberModal';
import { renderMember } from 'utils/forms/autocomplete';

interface IFormCadastrarEditarPerfilUsuarios {
	initialState: any;
	DataUsersGroup?: any;
	selectedUserTypes?: any;
	setSelectedUserTypes?: any;
	imagemTab?: any;
	setPayload?: any;
	payload?: any;
	cadastroTab?: any;
	additionalButtons?: any;
	additionalAutocompleteData?: any;
}

const FormCadastrarEditarPerfilUsuarios = ({
	initialState,
	DataUsersGroup,
	setSelectedUserTypes,
	imagemTab,
	payload,
	setPayload,
	cadastroTab,
	additionalAutocompleteData,
}: IFormCadastrarEditarPerfilUsuarios) => {
	const history = useHistory();

	const {
		isOpen: isOpenModalCreate,
		onOpen: onOpenModalCreate,
		onClose: onCloseModalCreate,
	} = useDisclosure();

	const keySalesRepresentative = 'Representante Comercial';

	const [salesRepresentativeSelected, setSalesRepresentativeSelected] =
		useState<boolean>(false);

	const [dadosMembro, setDadosMembro] = useState<{
		nome: string;
		email: string;
	}>({ nome: '', email: '' });

	const goToHistory = useCallback(() => {
		history.goBack();
	}, [history]);

	const optionGroups = useMemo(() => {
		if (!DataUsersGroup?.length) return [];

		return DataUsersGroup?.map((item) => ({
			label: item.descricao,
			value: item.id,
		}));
	}, [DataUsersGroup]);

	const onChangeUserTypes = useCallback(
		(values, _formProps) => {
			const { getValues, setValue } = _formProps || {};

			const { grupos } = getValues?.() || {};

			const salesRepresentative = grupos?.find?.(
				(el) => el?.label === keySalesRepresentative,
			);

			setSalesRepresentativeSelected(!!salesRepresentative);

			if (salesRepresentative) {
				setValue('grupos', [salesRepresentative]);
				setSelectedUserTypes(salesRepresentative);
			} else {
				setSelectedUserTypes(values);
			}
		},
		[setSelectedUserTypes],
	);

	const renderModalCreate = useCallback(() => {
		return (
			<CreateMemberModal
				isOpenModalCreate={isOpenModalCreate}
				onCloseModalCreate={onCloseModalCreate}
				form={'form'}
				onChange={() => {}}
				dadosMembro={dadosMembro}
			/>
		);
	}, [dadosMembro, isOpenModalCreate, onCloseModalCreate]);

	const handleOnAddMember = useCallback(() => {
		setDadosMembro({
			nome: payload?.dbUserData?.nome,
			email: payload?.dbUserData?.email,
		});

		setTimeout(() => {
			onOpenModalCreate();
		}, 150);
	}, [onOpenModalCreate, payload]);

	const renderRows = useCallback((): IFormRows[] => {
		const extraInput = {
			type: 'common',
			rowFlexProps,
			columns: [
				{
					...makeGenericFormInputStructure({
						type: 'autocomplete',
						name: 'membro_id',
						label: 'Associar a um Membro',
						showRequiredOnLabel: true,
						validate: { ...validateRequired() },
						inputProps: {
							'data-test':
								'autocomplete-membro-cadastro_editar-page-gerenciamento_usuarios',
							...defaultInputStyle,
							searchType: 'membro',
							initialLabel: additionalAutocompleteData?.membro
								? renderMember(
										additionalAutocompleteData?.membro,
								  )
								: '',
							renderInputLabel: renderMember,
							autocomplete: 'off',
						},
						columnFlexProps: {
							...columnFlexProps,
						},
						button: {
							onClick: handleOnAddMember,
							tooltip: 'Criar um novo membro',
							buttonProps: {
								bg: 'easyBLUE.300',
								color: 'white',
							},
							buttonFlexProps: {
								gap: 5,
								justify: 'space-between',
							},
						},
					}),
				},
			],
		};

		const inputs: any = [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'multiselect',
							name: 'grupos',
							label: 'Tipo de Usuário',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'multiselect-grupos-cadastro_editar-page-gerenciamento_usuarios',
								...defaultInputStyle,
								options: optionGroups,
								size: 'medium',
								placeholder: 'Selecione',
								notHideSelectedOptions: true,
								onChange: onChangeUserTypes,
							},
							columnFlexProps: {
								...columnFlexProps,
							},
						}),
					},
				],
			},
		];

		if (salesRepresentativeSelected) inputs.push(extraInput);

		return inputs;
	}, [
		handleOnAddMember,
		onChangeUserTypes,
		optionGroups,
		salesRepresentativeSelected,
		initialState?.nome_membro,
	]);

	const onSubmit = useCallback(
		(values) => {
			const grupos =
				values?.grupos?.map((item) => {
					return {
						id: item.value,
					};
				}) || [];
			setPayload((prevState) => ({
				...prevState,
				dbUserData: {
					...prevState.dbUserData,
					grupos: grupos,
					membro_id: values?.membro_id,
				},
			}));

			imagemTab.current.click();
		},
		[imagemTab, setPayload],
	);

	const renderForm = useCallback(() => {
		return (
			<>
				<GenericForm
					initialState={initialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToHistory}
					onGoBack={() => cadastroTab?.current?.click()}
					labelSave={'Avançar'}
					dataTestProps={{
						save: 'button-avancar-perfil-gerenciamento_usuarios',
						cancel: 'button-candelar-perfil-gerenciamento_usuarios',
					}}
				/>
				{renderModalCreate()}
			</>
		);
	}, [
		initialState,
		renderRows,
		onSubmit,
		goToHistory,
		renderModalCreate,
		cadastroTab,
	]);

	useEffect(() => {
		if (initialState?.grupos?.length) {
			const salesRepresentative = initialState?.grupos?.some?.(
				(el) => el?.label === keySalesRepresentative,
			);

			setSalesRepresentativeSelected(salesRepresentative);
		}
	}, [initialState?.grupos]);

	return renderForm();
};

export default FormCadastrarEditarPerfilUsuarios;