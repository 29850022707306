import {
	ButtonGroup,
	Icon,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import {
	KeyFilter,
	useContextColaboracaoConsenso,
} from 'pages/admin/demanda/templateCadastrarColabCons/context';
import { TbAlertTriangleFilled } from 'react-icons/tb';
import { useEditCollaborationConfig } from 'services/queryClient/wrapperHooks/useEditCollaborationConfig';
import { globalStyles } from '../../../src/theme/styles';
interface CollaborationConfigModalProps {
	isOpen: boolean;
	onClose: () => void;
	typeOfModal?: string;
	isCheckboxChecked: boolean;
}

interface CollaborationConfig {
	agrupar_key: 'agrupar_zona_venda' | 'agrupar_canal_venda';
	messageCheck: string;
	messageUncheck: string;
}
const collaborationConfigModal: Partial<
	Record<KeyFilter, CollaborationConfig>
> = {
	canal_venda: {
		agrupar_key: 'agrupar_canal_venda',
		messageCheck: 'por canal de venda?',
		messageUncheck: ' agregando todos os canais de venda?',
	},
	zona_venda: {
		agrupar_key: 'agrupar_zona_venda',
		messageCheck: 'por zona de venda?',
		messageUncheck: ' agregando todas as zonas de venda?',
	},
};
export const CollaborationConfigModal = ({
	isOpen,
	onClose,
	typeOfModal,
	isCheckboxChecked,
}: CollaborationConfigModalProps) => {
	const { mutateAsync } = useEditCollaborationConfig();
	const { cycleId, companyAreaId, setValuesFilter, valuesFilter } =
		useContextColaboracaoConsenso();
	const config: CollaborationConfig =
		collaborationConfigModal[typeOfModal || ''];

	if (!config) return null;

	const onSubmitAction = (): void => {
		const bodyToMutate = {
			ciclo_id: cycleId,
			area_empresa_id: companyAreaId,
			[config.agrupar_key]: !isCheckboxChecked,
		};
		mutateAsync(bodyToMutate as any).then(() => {
			return setValuesFilter({
				...valuesFilter,
				canal_venda: undefined,
				zona_venda: undefined,
			});
		});

		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={'2xl'}
			isCentered
			closeOnOverlayClick={false}
		>
			<ModalOverlay />
			<ModalContent borderRadius={'3xl'}>
				<ModalHeader>
					<Icon
						as={TbAlertTriangleFilled}
						color={'easyRED.300'}
						boxSize={12}
					/>
					<Text
						fontWeight={700}
						color={'easyBLUE.300'}
						fontSize={'2xl'}
					>
						{renderTextTitle()}
					</Text>
				</ModalHeader>

				<ModalBody mb={4}>
					<Text color='gray.500'>
						{renderTextBody(config, isCheckboxChecked)}
					</Text>
				</ModalBody>
				<ModalFooter>
					<ButtonGroup spacing={4}>
						<ButtonComponent
							data-test='button-cancelar-agrupar_todos_os_canais-modal-colaboracao'
							type={'ghost'}
							title={'Cancelar'}
							onClick={onClose}
						/>
						<ButtonComponent
							data-test='button-salvar-agrupar_todos_os_canais-modal-colaboracao'
							type={'secondary'}
							title={'Confimar'}
							onClick={onSubmitAction}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export const renderTextTitle = (): JSX.Element => {
	return (
		<>
			ATENÇÃO, esta ação resultará na{' '}
			<span style={{ color: globalStyles.colors.easyRED[300] }}>
				remoção permanente{' '}
			</span>
			de todas as colaborações realizadas no ciclo e colaborador
			selecionados.
		</>
	);
};

export const renderTextBody = (
	config: CollaborationConfig,
	check: boolean,
): string => {
	const startMessage = `Tem certeza que deseja prosseguir com a colaboração`;
	const { messageCheck, messageUncheck } = config;
	return `${startMessage} ${check ? messageCheck : messageUncheck}`;
};
