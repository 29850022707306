import { Icon } from '@chakra-ui/react';
import {
	IconMap2,
	IconFilter,
	IconSettingsAutomation,
	IconGolf,
	IconCalendarStats,
	IconId,
	IconLock,
} from '@tabler/icons';

// Pages Easy360

import EstoqueAtual from 'pages/admin/capacidade/estoqueAtual';
import EstoqueDesejado from 'pages/admin/capacidade/estoqueDesejado';
import Crm from 'pages/admin/crm';
import Colaboracao from 'pages/admin/demanda/colaboracao';
import Consenso from 'pages/admin/demanda/consenso';
import Dashboard from 'pages/admin/demanda/dashboard';
import TicketMedio from 'pages/admin/demanda/ticketMedio';
import Mercado from 'pages/admin/mercado';

import LancamentoHoraHora from 'pages/admin/rotina/operacoes/lancamentoHoraHora';

// 🢂 Cadastros: Menu (Melhoria: definir padrão de nomeação)
import CadastroProduto from 'pages/admin/cadastros/produto';
import CadastroProdutos from 'pages/admin/cadastros/produto/produtos';

import CadastroComercial from 'pages/admin/cadastros/comercial';
import CadastroCidades from 'pages/admin/cadastros/comercial/cidades';
import CadastroMembros from 'pages/admin/cadastros/comercial/membros';
import CadastroMercado from 'pages/admin/cadastros/comercial/hierarquia';
import ListingSales from 'pages/admin/cadastros/comercial/vendas';

import CadastroClientes from 'pages/admin/cadastros/clientes';
import CadastroClientesRelacionamento from 'pages/admin/cadastros/clientes/clientesRelacionamento';

import CadastroCapacidade from 'pages/admin/cadastros/capacidade';
import CadastroDiasUteis from 'pages/admin/cadastros/capacidade/diasUteis';
import Recursos from 'pages/admin/cadastros/capacidade/recursos';
import CadastroRoteiros from 'pages/admin/cadastros/capacidade/roteiros';

import CadastrarRecurso from 'pages/admin/cadastros/capacidade/recursos/cadastrar';
import EditarRecurso from 'pages/admin/cadastros/capacidade/recursos/editar';
import CadastrarClientesRelacionamento from 'pages/admin/cadastros/clientes/clientesRelacionamento/cadastrar';
import EditarClienteRelacionamento from 'pages/admin/cadastros/clientes/clientesRelacionamento/editar';
import CadastrarCidades from 'pages/admin/cadastros/comercial/cidades/cadastrar';
import EditarCidade from 'pages/admin/cadastros/comercial/cidades/editar';
import CadastrarMembros from 'pages/admin/cadastros/comercial/membros/cadastrar';
import EditarMembro from 'pages/admin/cadastros/comercial/membros/editar';
import CadastrarHierarquia from 'pages/admin/cadastros/comercial/hierarquia/cadastrar';
import EditarMercado from 'pages/admin/cadastros/comercial/hierarquia/editar';
import CadastrarVendas from 'pages/admin/cadastros/comercial/vendas/cadastrar';
import EditarVenda from 'pages/admin/cadastros/comercial/vendas/editar';
import CadastroProducao from 'pages/admin/cadastros/producao';
import CadastroMetas from 'pages/admin/cadastros/producao/metas';
import CadastroParadas from 'pages/admin/cadastros/producao/paradas';
import CadastrarTipologias from 'pages/admin/cadastros/producao/paradas/cadastrar';
import EditarTipologia from 'pages/admin/cadastros/producao/paradas/editar';
import CadastrarFamilias from 'pages/admin/cadastros/produto/familia/cadastrar';
import CadastrarProdutos from 'pages/admin/cadastros/produto/produtos/cadastrar';
import EditarProduto from 'pages/admin/cadastros/produto/produtos/editar';
import CadastrarUsuarios from 'pages/admin/cadastros/usuarios/perfis/cadastrar';
import EditarUsuarios from 'pages/admin/cadastros/usuarios/perfis/editar';
// import GerenciarConta from 'pages/admin/cadastros/usuarios/perfis/gerenciarConta';
import GerenciarPerfil from 'pages/admin/cadastros/usuarios/perfis/gerenciarPerfil';
import CadastroPerfil from 'pages/admin/cadastros/usuarios/perfis/index';
import MinhaConta from 'pages/admin/cadastros/usuarios/perfis/minhaConta';
import DadosPagamento from 'pages/admin/cadastros/usuarios/perfis/pagamento';
import PlanosAssinatura from 'pages/admin/cadastros/usuarios/perfis/planos';
import DashboardCapacidade from 'pages/admin/capacidade/dashboardCapacidade';
import CadastrarEstoqueAtual from 'pages/admin/capacidade/estoqueAtual/cadastrar';
import EditarEstoqueAtual from 'pages/admin/capacidade/estoqueAtual/editar';
import CadastroOportunidades from 'pages/admin/crm/oportunidades';
import CadastrarOportunidades from 'pages/admin/crm/oportunidades/cadastrar';
import EditarOportunidades from 'pages/admin/crm/oportunidades/editar';
import CadastrarColaboracao from 'pages/admin/demanda/colaboracao/cadastrar';
import CadastrarConsenso from 'pages/admin/demanda/consenso/cadastrar';
import EditarPrecoMedio from 'pages/admin/demanda/ticketMedio/editar';
import CadastrarCarteira from 'pages/admin/rotina/vendas/carteira/cadastrar';
import EditarCarteira from 'pages/admin/rotina/vendas/carteira/editar';
import RotinaCarteira from 'pages/admin/rotina/vendas/carteira/index';
import DashboardVendas from 'pages/admin/rotina/vendas/dashboard';
import CadastrarForecast from 'pages/admin/rotina/vendas/forecast/cadastrar';
import EditarForecast from 'pages/admin/rotina/vendas/forecast/editar';
import RotinaForecast from 'pages/admin/rotina/vendas/forecast/index';
import CadastrarOrcamentos from 'pages/admin/rotina/vendas/orcamento/cadastrar';
import EditarOrcamento from 'pages/admin/rotina/vendas/orcamento/editar';
import RotinaOrcamento from 'pages/admin/rotina/vendas/orcamento/index';
import CadenciaVendas from 'pages/admin/rotina/vendas/cadencia';
import ConfiguracoesUsuario from 'pages/admin/settings';
import RegistrarUsuario from 'pages/auth/signIn/RegistrarUsuario';
import DashboardProducao from 'pages/admin/rotina/operacoes/dashboardProducao';
import Introdução from 'pages/admin/home';
import {
	ModulesPermissionsUser,
	PermissionsPagesUser,
} from 'contexts/models/globalContextModels';
import CadastrarPrecoMedio from 'pages/admin/demanda/ticketMedio/cadastrar';
import Acuracidade from '../pages/admin/demanda/acuracidade';
import { environmentVars } from 'services/api/variables/environment';
import BotsSettings from 'pages/admin/bots/settings';
import BotsProcessLogs from 'pages/admin/bots/processLogs';
import AiPredictionLogsList from 'pages/admin/ai/processLogs';
import RateioNovo from 'pages/admin/demanda/rateio/rateio';

const getRoutesAdminCompany = (
	pathCompany: string,
	permissions: PermissionsPagesUser,
	modulesPermissions: ModulesPermissionsUser,
	userAttributes: any,
) => {
	const routes = [
		// --- Mercado ---
		{
			name: 'Análise de Mercado',
			path: '/analiseMercado',
			icon: (
				<Icon
					as={IconMap2}
					width='20px'
					height='20px'
					color='inherit'
				/>
			),
			// Implementação de módulo bloqueado para plano gratuito quando tiver presente nas próximas versões
			// iconLock: (permissions?.plano?.tipo === "gratuito" ? <Icon as={IconLock} width='20px' height='20px' color='inherit' /> : undefined),
			// Implementação provisória de módulo bloqueado para V1
			iconLock: (
				<Icon
					as={IconLock}
					width='20px'
					height='20px'
					color='inherit'
				/>
			),
			collapse: true,
			items: [
				{
					name: 'Mapeamento de Mercado',
					layout: `/${pathCompany}`,
					path: '/analiseMercado/mercado',
					component: Mercado,
					visualization: permissions?.mapeamentoDeMercado?.visualizar,
				},
			],
		},
		// --- Home ---

		// --- CRM ---
		{
			name: 'Gestão de Relacionamento',
			path: '/crm',
			icon: (
				<Icon
					as={IconFilter}
					width='20px'
					height='20px'
					color='inherit'
				/>
			),
			// Implementação de módulo bloqueado para plano gratuito quando tiver presente nas próximas versões
			// iconLock: (permissions?.plano?.tipo === "gratuito" ? <Icon as={IconLock} width='20px' height='20px' color='inherit' /> : undefined),
			iconLock: (
				<Icon
					as={IconLock}
					width='20px'
					height='20px'
					color='inherit'
				/>
			),
			permissions:
				modulesPermissions?.crm?.permissions &&
				permissions?.crm?.visualizar,
			link: `${environmentVars.procedo.url}${userAttributes?.token}`,
			collapse: true,
			items: [],
			component: Crm,
			modal: true,
		},
		// --- Previsao da Demanda ---
		{
			name: 'Previsão da Demanda',
			path: '/demanda',
			icon: (
				<Icon
					as={IconGolf}
					width='20px'
					height='20px'
					color='inherit'
				/>
			),
			collapse: true,
			items: [
				{
					name: 'Dashboard de Demanda',
					layout: `/${pathCompany}`,
					path: '/demanda/dashboardQuantidade',
					// endpoint com grafia incorreta
					component: Dashboard,
					exact: true,
					visualization: permissions?.dashboardDeDemanda?.visualizar,
				},
				{
					name: 'Acuracidade',
					layout: `/${pathCompany}`,
					path: '/demanda/acuracidade',
					// endpoint com grafia incorreta
					component: Acuracidade,
					exact: true,
					visualization: permissions?.dashboardDeDemanda?.visualizar,
				},
				{
					name: 'Colaboração',
					layout: `/${pathCompany}`,
					path: '/demanda/colaboracao/criar',
					component: CadastrarColaboracao,
					templatePage: 'collaborationConsenting',
					notShowItemMenu: true,
					parent: {
						name: 'Colaborações',
						path: '/demanda/colaboracao',
					},
					visualization: permissions?.colaboracoes?.cadastrar,
				},
				{
					name: 'Colaborações',
					layout: `/${pathCompany}`,
					path: '/demanda/colaboracao',
					component: Colaboracao,
					visualization: permissions?.colaboracoes?.visualizar,
				},
				{
					name: 'Consenso',
					layout: `/${pathCompany}`,
					path: '/demanda/consenso/criar',
					component: CadastrarConsenso,
					templatePage: 'collaborationConsenting',
					notShowItemMenu: true,
					parent: {
						name: 'Consenso',
						path: '/demanda/consenso',
					},
					visualization: permissions?.consensos?.cadastrar,
				},
				{
					name: 'Consensos',
					layout: `/${pathCompany}`,
					path: '/demanda/consenso',
					component: Consenso,
					visualization: permissions?.consensos?.visualizar,
				},
				{
					name: 'Rateio',
					layout: `/${pathCompany}`,
					path: '/demanda/rateio',
					component: RateioNovo,
					visualization: permissions?.rateio?.visualizar,
				},
				{
					name: 'Cadastrar Preço Médio',
					layout: `/${pathCompany}`,
					component: CadastrarPrecoMedio,
					path: '/demanda/ticketMedio/criar',
					notShowItemMenu: true,
					parent: {
						name: 'Preço Médio',
						path: '/demanda/ticketMedio',
					},
					visualization: permissions?.precoMedio?.cadastrar,
				},
				{
					name: 'Preço Médio',
					layout: `/${pathCompany}`,
					path: '/demanda/ticketMedio',
					component: TicketMedio,
					visualization: permissions?.precoMedio?.visualizar,
				},
				{
					name: 'Editar Preço Médio',
					layout: `/${pathCompany}`,
					component: EditarPrecoMedio,
					path: '/demanda/editar/ticketMedio/:id',
					notShowItemMenu: true,
					editPath: '/demanda/editar/ticketMedio',
					parent: {
						name: 'Preço Médio',
						path: '/demanda/ticketMedio',
					},
					visualization: permissions?.precoMedio?.editar,
				},
			],
		},
		// --- Analise de Capacidade ---
		{
			name: 'Análise de Capacidade',
			path: '/analiseCapacidade',
			icon: (
				<Icon
					as={IconSettingsAutomation}
					width='20px'
					height='20px'
					color='inherit'
				/>
			),
			collapse: true,
			items: [
				{
					name: 'Dashboard Capacidade',
					layout: `/${pathCompany}`,
					path: '/analiseCapacidade/dashboard',
					component: DashboardCapacidade,
					visualization: permissions?.dashboardCapacidade?.visualizar,
				},
				{
					name: 'Estoque Atual',
					layout: `/${pathCompany}`,
					path: '/analiseCapacidade/estoqueAtual',
					component: EstoqueAtual,
					visualization: permissions?.estoqueAtual?.visualizar,
				},
				{
					name: 'Cadastrar Estoque',
					layout: `/${pathCompany}`,
					path: '/analiseCapacidade/estoque/criar',
					exact: false,
					notShowItemMenu: true,
					component: CadastrarEstoqueAtual,
					parent: {
						name: 'Estoque Atual',
						path: '/analiseCapacidade/estoqueAtual',
					},
					visualization: permissions?.estoqueAtual?.cadastrar,
				},
				{
					name: 'Editar Estoque',
					layout: `/${pathCompany}`,
					path: '/analiseCapacidade/estoque/editar/:id',
					exact: false,
					component: EditarEstoqueAtual,
					notShowItemMenu: true,
					editPath: '/analiseCapacidade/estoque/editar',
					parent: {
						name: 'Estoque Atual',
						path: '/analiseCapacidade/estoqueAtual',
					},
					visualization: permissions?.estoqueAtual?.editar,
				},
				{
					name: 'Orientação de Estoque',
					layout: `/${pathCompany}`,
					path: '/analiseCapacidade/estoqueDesejado',
					component: EstoqueDesejado,
					visualization: permissions?.estoqueDesejado?.visualizar,
				},
			],
		},
		// --- Gestão da Rotina ---
		{
			name: 'Gestão de Rotina',
			path: '/rotina',
			icon: (
				<Icon
					as={IconCalendarStats}
					width='20px'
					height='20px'
					color='inherit'
				/>
			),
			collapse: true,
			items: [
				{
					name: 'Rotina de Operações',
					path: '/rotina/operacoes',
					collapse: true,
					items: [
						{
							name: 'Dashboard de Produção',
							layout: `/${pathCompany}`,
							path: '/rotina/operacoes/dashboardProducao',
							component: DashboardProducao,
							visualization:
								permissions?.lancamentoHoraHora?.visualizar,
						},
						{
							name: 'Lançamento Diário',
							alternativeName: 'Lançamento Diário de Produção',
							layout: `/${pathCompany}`,
							path: '/rotina/operacoes/lancamentoDiario',
							component: LancamentoHoraHora,
							visualization:
								permissions?.lancamentoHoraHora?.visualizar,
						},
					],
				},
				{
					name: 'Rotina de Vendas',
					path: '/rotina/vendas',
					collapse: true,
					items: [
						{
							name: 'Dashboard de Vendas',
							layout: `/${pathCompany}`,
							path: '/rotina/vendas/dashboard',
							exact: true,
							component: DashboardVendas,
							visualization:
								permissions?.dashboardVendas?.visualizar,
						},
						{
							name: 'Cadência de Vendas',
							layout: `/${pathCompany}`,
							path: '/rotina/vendas/cadenciaVendas',
							exact: false,
							component: CadenciaVendas,
							// Trocar no back para cadenciaVendas
							visualization: permissions?.projecoes?.visualizar,
						},
						{
							name: 'Carteira de Pedidos',
							layout: `/${pathCompany}`,
							path: '/rotina/vendas/carteira',
							exact: false,
							component: RotinaCarteira,
							visualization:
								permissions?.carteiraPedido?.visualizar,
						},
						{
							name: 'Cadastrar Carteira de Pedidos',
							layout: `/${pathCompany}`,
							path: '/rotina/carteira/criar',
							exact: false,
							notShowItemMenu: true,
							component: CadastrarCarteira,
							parent: {
								name: 'Carteira de Pedidos',
								path: '/rotina/vendas/carteira',
							},
							visualization:
								permissions?.carteiraPedido?.cadastrar,
						},
						{
							name: 'Editar Carteira de Pedidos',
							layout: `/${pathCompany}`,
							path: '/rotina/carteira/editar/:id',
							exact: false,
							notShowItemMenu: true,
							component: EditarCarteira,
							editPath: '/rotina/carteira/editar',
							parent: {
								name: 'Carteira de Pedidos',
								path: '/rotina/vendas/carteira',
							},
							visualization: permissions?.carteiraPedido?.editar,
						},
						{
							name: 'Oportunidades',
							layout: `/${pathCompany}`,
							path: '/rotina/vendas/oportunidades',
							component: CadastroOportunidades,
							visualization:
								permissions?.oportunidades?.visualizar,
						},
						{
							name: 'Cadastrar Oportunidades',
							layout: `/${pathCompany}`,
							component: CadastrarOportunidades,
							path: '/rotina/oportunidades/criar',
							notShowItemMenu: true,
							parent: {
								name: 'Oportunidades',
								path: '/rotina/vendas/oportunidades',
							},
							visualization:
								permissions?.oportunidades?.cadastrar,
						},
						{
							name: 'Editar Oportunidades',
							layout: `/${pathCompany}`,
							component: EditarOportunidades,
							path: '/rotina/oportunidades/editar/:id',
							notShowItemMenu: true,
							editPath: '/rotina/oportunidades/editar',
							parent: {
								name: 'Oportunidades',
								path: '/rotina/vendas/oportunidades',
							},
							visualization: permissions?.oportunidades?.editar,
						},
						{
							name: 'Forecast',
							layout: `/${pathCompany}`,
							path: '/rotina/vendas/forecast',
							exact: false,
							component: RotinaForecast,
							visualization: permissions?.forecast?.visualizar,
						},
						{
							name: 'Cadastrar Forecast',
							layout: `/${pathCompany}`,
							path: '/rotina/forecast/criar',
							exact: false,
							notShowItemMenu: true,
							component: CadastrarForecast,
							parent: {
								name: 'Forecast',
								path: '/rotina/vendas/forecast',
							},
							visualization: permissions?.forecast?.cadastrar,
						},
						{
							name: 'Editar Forecast',
							layout: `/${pathCompany}`,
							path: '/rotina/forecast/editar/:id',
							exact: false,
							notShowItemMenu: true,
							component: EditarForecast,
							editPath: '/rotina/forecast/editar',
							parent: {
								name: 'Forecast',
								path: '/rotina/vendas/forecast',
							},
							visualization: permissions?.forecast?.editar,
						},
						{
							name: 'Orçamento',
							layout: `/${pathCompany}`,
							path: '/rotina/vendas/orcamento',
							exact: false,
							component: RotinaOrcamento,
							visualization: permissions?.orcamento?.visualizar,
						},
						{
							name: 'Cadastrar Orçamentos',
							layout: `/${pathCompany}`,
							path: '/rotina/orcamento/criar',
							exact: false,
							notShowItemMenu: true,
							component: CadastrarOrcamentos,
							parent: {
								name: 'Orçamento',
								path: '/rotina/vendas/orcamento',
							},
							visualization: permissions?.orcamento?.cadastrar,
						},
						{
							name: 'Editar Orçamento',
							layout: `/${pathCompany}`,
							path: '/rotina/orcamento/editar/:id',
							exact: false,
							notShowItemMenu: true,
							component: EditarOrcamento,
							editPath: '/rotina/orcamento/editar',
							parent: {
								name: 'Orçamento',
								path: '/rotina/vendas/orcamento',
							},
							visualization: permissions?.orcamento?.editar,
						},
					],
				},
			],
		},

		// 🢂 Cadastros: Menu (Melhoria: definir padrão de nomeação)
		{
			name: 'Cadastros',
			path: '/cadastros',
			icon: (
				<Icon
					as={IconId}
					width='20px'
					height='20px'
					color='inherit'
				/>
			),
			collapse: true,
			items: [
				{
					name: 'Produto',
					path: '/cadastros/produto',
					collapse: true,
					items: [
						{
							name: 'Família',
							layout: `/${pathCompany}`,
							component: CadastrarFamilias,
							path: '/cadastros/produto/familia/criar',
							notShowItemMenu: false,
							visualization: permissions?.familia?.visualizar,
						},
						{
							name: 'Cadastrar Produtos',
							layout: `/${pathCompany}`,
							component: CadastrarProdutos,
							path: '/cadastros/produto/criar',
							notShowItemMenu: true,
							parent: {
								name: 'Produtos',
								path: '/cadastros/produto/produtos',
							},
							visualization: permissions?.produtos?.cadastrar,
						},
						{
							name: 'Editar Produtos',
							layout: `/${pathCompany}`,
							component: EditarProduto,
							path: '/cadastros/produto/editar/:id',
							notShowItemMenu: true,
							editPath: '/cadastros/produto/editar',
							parent: {
								name: 'Produtos',
								path: '/cadastros/produto/produtos',
							},
							visualization: permissions?.produtos?.editar,
						},
						{
							name: 'Produtos',
							layout: `/${pathCompany}`,
							path: '/cadastros/produto/produtos',
							component: CadastroProdutos,
							visualization: permissions?.produtos?.visualizar,
						},
					],
					component: CadastroProduto,
				},
				{
					name: 'Comercial',
					path: '/cadastros/comercial',
					collapse: true,
					items: [
						{
							name: 'Hierarquia',
							layout: `/${pathCompany}`,
							path: '/cadastros/comercial/hierarquia',
							exact: false,
							component: CadastroMercado,
							visualization: permissions?.mercado?.visualizar,
						},
						{
							name: 'Cadastrar Hierarquias',
							layout: `/${pathCompany}`,
							path: '/cadastros/hierarquias/criar',
							exact: false,
							notShowItemMenu: true,
							component: CadastrarHierarquia,
							parent: {
								name: 'Hierarquia',
								path: '/cadastros/comercial/hierarquia',
							},
							visualization: permissions?.mercado?.cadastrar,
						},
						{
							name: 'Editar Hierarquias',
							layout: `/${pathCompany}`,
							path: '/cadastros/hierarquia/editar/:id',
							exact: false,
							notShowItemMenu: true,
							component: EditarMercado,
							editPath: '/cadastros/hierarquia/editar',
							parent: {
								name: 'Hierarquia',
								path: '/cadastros/comercial/hierarquia',
							},
							visualization: permissions?.mercado?.editar,
						},
						{
							name: 'Membros',
							layout: `/${pathCompany}`,
							path: '/cadastros/comercial/membro',
							exact: false,
							component: CadastroMembros,
							visualization: permissions?.membros?.visualizar,
						},
						{
							name: 'Cadastrar Membros',
							layout: `/${pathCompany}`,
							path: '/cadastros/membros/criar',
							exact: false,
							notShowItemMenu: true,
							component: CadastrarMembros,
							parent: {
								name: 'Membros',
								path: '/cadastros/comercial/membro',
							},
							visualization: permissions?.membros?.cadastrar,
						},
						{
							name: 'Editar Membros',
							layout: `/${pathCompany}`,
							path: '/cadastros/membro/editar/:id',
							// exact: false,
							notShowItemMenu: true,
							component: EditarMembro,
							editPath: '/cadastros/membro/editar',
							parent: {
								name: 'Membros',
								path: '/cadastros/comercial/membro',
							},
							visualization: permissions?.membros?.editar,
						},
						{
							name: 'Cidades',
							layout: `/${pathCompany}`,
							path: '/cadastros/comercial/cidade',
							exact: false,
							component: CadastroCidades,
							visualization: permissions?.cidades?.visualizar,
						},
						{
							name: 'Cadastrar Cidades',
							layout: `/${pathCompany}`,
							path: '/cadastros/cidades/criar',
							exact: false,
							notShowItemMenu: true,
							component: CadastrarCidades,
							parent: {
								name: 'Cidades',
								path: '/cadastros/comercial/cidade',
							},
							visualization: permissions?.cidades?.cadastrar,
						},
						{
							name: 'Editar Cidades',
							layout: `/${pathCompany}`,
							path: '/cadastros/cidade/editar/:id',
							// exact: false,
							notShowItemMenu: true,
							component: EditarCidade,
							editPath: '/cadastros/cidade/editar',
							parent: {
								name: 'Cidades',
								path: '/cadastros/comercial/cidade',
							},
							visualization: permissions?.cidades?.editar,
						},
						{
							name: 'Vendas',
							layout: `/${pathCompany}`,
							path: '/cadastros/comercial/venda',
							// exact: false,
							component: ListingSales,
							visualization: permissions?.vendas?.visualizar,
						},
						{
							name: 'Cadastrar Vendas',
							layout: `/${pathCompany}`,
							path: '/cadastros/venda/criar',
							// exact: false,
							notShowItemMenu: true,
							component: CadastrarVendas,
							parent: {
								name: 'Vendas',
								path: '/cadastros/comercial/venda',
							},
							visualization: permissions?.vendas?.cadastrar,
						},
						{
							name: 'Editar Vendas',
							layout: `/${pathCompany}`,
							path: '/cadastros/venda/editar/:id',
							// exact: false,
							notShowItemMenu: true,
							component: EditarVenda,
							editPath: '/cadastros/venda/editar',
							parent: {
								name: 'Vendas',
								path: '/cadastros/comercial/venda',
							},
							visualization: permissions?.vendas?.editar,
						},
					],
					component: CadastroComercial,
				},
				{
					name: 'Clientes',
					path: '/cadastros/clientes',
					collapse: true,
					items: [
						{
							name: 'Cliente Relacionamento',
							layout: `/${pathCompany}`,
							path: '/cadastros/clientes/clienteRelacionado',
							exact: false,
							component: CadastroClientesRelacionamento,
							visualization:
								permissions?.clienteRelacionamento?.visualizar,
						},
						{
							name: 'Cadastrar Cliente Relacionamento',
							layout: `/${pathCompany}`,
							path: '/cadastros/clienteRelacionado/criar',
							exact: false,
							notShowItemMenu: true,
							component: CadastrarClientesRelacionamento,
							parent: {
								name: 'Cliente Relacionamento',
								path: '/cadastros/clientes/clienteRelacionado',
							},
							visualization:
								permissions?.clienteRelacionamento?.cadastrar,
						},
						{
							name: 'Editar Cliente Relacionamento',
							layout: `/${pathCompany}`,
							path: '/cadastros/clienteRelacionamento/editar/:id',
							exact: false,
							notShowItemMenu: true,
							component: EditarClienteRelacionamento,
							editPath: '/cadastros/clienteRelacionamento/editar',
							parent: {
								name: 'Cliente Relacionamento',
								path: '/cadastros/clientes/clienteRelacionado',
							},
							visualization:
								permissions?.clienteRelacionamento?.editar,
						},
					],
					component: CadastroClientes,
				},
				{
					name: 'Capacidade',
					path: '/cadastros/capacidade',
					collapse: true,
					items: [
						{
							name: 'Recursos',
							layout: `/${pathCompany}`,
							path: '/cadastros/capacidade/recurso',
							exact: false,
							component: Recursos,
							visualization: permissions?.recursos?.visualizar,
						},
						{
							name: 'Cadastrar Recurso',
							layout: `/${pathCompany}`,
							path: '/cadastros/recursos/cadastrar',
							exact: false,
							component: CadastrarRecurso,
							notShowItemMenu: true,
							parent: {
								name: 'Recursos',
								path: '/cadastros/capacidade/recurso',
							},
							visualization: permissions?.recursos?.cadastrar,
						},
						{
							name: 'Editar Recurso',
							layout: `/${pathCompany}`,
							path: '/cadastros/recursos/editar/:id',
							exact: false,
							component: EditarRecurso,
							notShowItemMenu: true,
							editPath: '/cadastros/recursos/editar',
							parent: {
								name: 'Recursos',
								path: '/cadastros/capacidade/recurso',
							},
							visualization: permissions?.recursos?.editar,
						},
						{
							name: 'Dias Úteis',
							layout: `/${pathCompany}`,
							path: '/cadastros/capacidade/diaUtil',
							exact: false,
							component: CadastroDiasUteis,
							visualization: permissions?.diasUteis?.visualizar,
						},
						{
							name: 'Roteiros',
							layout: `/${pathCompany}`,
							path: '/cadastros/capacidade/roteiroProducao',
							exact: false,
							component: CadastroRoteiros,
							visualization: permissions?.roteiros?.visualizar,
						},
					],
					component: CadastroCapacidade,
				},
				{
					name: 'Produção',
					path: '/cadastros/producao',
					collapse: true,
					items: [
						{
							name: 'Paradas',
							layout: `/${pathCompany}`,
							path: '/cadastros/producao/tipologiaParadas',
							exact: false,
							component: CadastroParadas,
							visualization:
								permissions?.tipologiasDeParadas?.visualizar,
						},
						{
							name: 'Cadastrar Parada',
							layout: `/${pathCompany}`,
							path: '/cadastros/producao/tipologia/cadastrar',
							exact: false,
							component: CadastrarTipologias,
							notShowItemMenu: true,
							parent: {
								name: 'Paradas',
								path: '/cadastros/producao/tipologiaParadas',
							},
							visualization:
								permissions?.tipologiasDeParadas?.cadastrar,
						},
						{
							name: 'Editar Parada',
							layout: `/${pathCompany}`,
							path: '/cadastros/producao/tipologia/editar/:id',
							exact: false,
							component: EditarTipologia,
							notShowItemMenu: true,
							editPath: '/cadastros/producao/tipologia/editar',
							parent: {
								name: 'Paradas',
								path: '/cadastros/producao/tipologiaParadas',
							},
							visualization:
								permissions?.tipologiasDeParadas?.editar,
						},
						{
							name: 'Metas',
							layout: `/${pathCompany}`,
							path: '/cadastros/producao/metaProducao',
							exact: false,
							component: CadastroMetas,
							visualization: permissions?.metas?.visualizar,
						},
					],
					component: CadastroProducao,
				},
				{
					name: 'Usuários',
					path: '/cadastros/usuarios',
					collapse: true,
					notShowItemMenu: true,
					items: [
						{
							name: 'Gerenciamento de Usuários',
							layout: `/${pathCompany}`,
							path: '/gerenciamentoUsuarios',
							exact: false,
							notShowItemMenu: true,
							component: CadastroPerfil,
							visualization: permissions?.perfis?.visualizar,
						},
						{
							name: 'Cadastrar Usuário',
							layout: `/${pathCompany}`,
							path: '/gerenciamentoUsuarios/criar',
							exact: false,
							component: CadastrarUsuarios,
							notShowItemMenu: true,
							parent: {
								name: 'Perfis',
								path: '/gerenciamentoUsuarios',
							},
							visualization: permissions?.perfis?.cadastrar,
						},
						{
							name: 'Editar Usuário',
							layout: `/${pathCompany}`,
							component: EditarUsuarios,
							path: '/gerenciamentoUsuarios/editar/usuarios/:id',
							notShowItemMenu: true,
							editPath: '/gerenciamentoUsuarios/editar/usuarios',
							parent: {
								name: 'Perfis',
								path: '/gerenciamentoUsuarios',
							},
							visualization: permissions?.perfis?.editar,
						},
						{
							name: 'Gerenciar Perfil',
							layout: `/${pathCompany}`,
							component: GerenciarPerfil,
							path: '/gerenciarPerfil',
							notShowItemMenu: true,
							visualization:
								permissions?.gerenciarConta?.visualizar,
						},
						{
							name: 'Minha Conta',
							layout: `/${pathCompany}`,
							component: MinhaConta,
							path: '/minhaConta',
							notShowItemMenu: true,
							visualization: permissions?.minhaConta?.visualizar,
						},
						{
							name: 'Dados para Pagamento',
							layout: `/${pathCompany}`,
							component: DadosPagamento,
							path: '/cadastros/usuarios/pagamento',
							notShowItemMenu: true,
							visualization: permissions?.pagamento?.visualizar,
						},
						{
							name: 'Configurações da Easy360',
							layout: `/${pathCompany}`,
							path: '/configuracoes',
							exact: false,
							component: ConfiguracoesUsuario,
							notShowItemMenu: true,
							visualization:
								permissions?.configuracoesDaEasy?.visualizar,
						},
						{
							name: 'Planos de Assinatura',
							layout: `/${pathCompany}`,
							path: '/planos',
							exact: false,
							component: PlanosAssinatura,
							templatePage: 'plans',
							notShowItemMenu: true,
							visualization: permissions?.planos?.visualizar,
						},
						{
							name: 'Registrar',
							layout: `/${pathCompany}`,
							path: '/cadastros/usuarios/registrar',
							exact: false,
							component: RegistrarUsuario,
							templatePage: 'register',
							notShowItemMenu: true,
							visualization:
								permissions?.registrarEmpresa?.cadastrar,
						},
						{
							name: 'Configurações do Robô',
							path: '/bots/settings',
							layout: `/${pathCompany}`,
							exact: false,
							component: BotsSettings,
							visualization: permissions?.bots?.visualizar,
							notShowItemMenu: true,
						},
						{
							name: 'Atividades Recentes do Robô',
							path: '/bots/logs',
							layout: `/${pathCompany}`,
							exact: false,
							component: BotsProcessLogs,
							visualization: true,
							notShowItemMenu: true,
						},
						{
							name: 'Atividades Recentes da IA',
							path: '/ai-prediction/logs',
							layout: `/${pathCompany}`,
							component: AiPredictionLogsList,
							visualization:
								permissions?.aiPrediction?.visualizar,
							notShowItemMenu: true,
						},
					],
					component: EditarUsuarios,
				},
			],
		},
		{
			name: 'Introdução',
			path: '/',
			collapse: true,
			notShowItemMenu: true,
			items: [
				{
					name: 'Introdução',
					layout: `/${pathCompany}`,
					path: '/',
					notShowItemMenu: true,
					component: Introdução,
					visualization: true,
				},
			],
		},
	];
	return routes;
};

export default getRoutesAdminCompany;
